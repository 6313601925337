html {
    background: #f8f8f8;
}

body {
    padding: 0;
    margin: 0;
    max-width: 1024px;
    margin: 0 auto;
    display: grid;
    place-items: center;
    height: 100vh;
}
h1 {
    text-align: center;
    margin-bottom: 1rem;
}
.grid {
    display: grid;
    grid-template-rows: repeat(8, 1fr);
    grid-template-columns: repeat(8, 1fr);
    width: calc(min(30rem, 100vw));
    aspect-ratio: 1;
    gap: 0.4rem;
    margin: 0 auto;
}

.cell {
    display: grid;
    place-items: center;
    position: relative;
    width: 100%;
    height: 100%;
}

.cell.next {
    outline: 6px solid red;
    border-radius: 0.5rem;
}

.piece {
    z-index: 1;
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    left: 0%;
    top: 0%;
    outline: 0.9rem solid #333;
    outline-offset: -1rem;
}
.piece.black {
    outline-color: #333;
}
.piece.white {
    outline-color: #f3f2f2;
}

/** palette mostly kgolid's curated tundra3 https://chromotome-quicker.netlify.app/ */
.piece.yellow,
.cell.yellow {
    background-color: #d9c67a;
}
.piece.orange,
.cell.orange {
    background-color: #eb7f64;
}
.piece.pink,
.cell.pink {
    background-color: #ca87c7;
}
.piece.purple,
.cell.purple {
    background-color: #7b7377;
}
.piece.blue,
.cell.blue {
    background-color: #87c3ca;
}
.piece.green,
.cell.green {
    background-color: #89ca87;
}
.piece.red,
.cell.red {
    background-color: #b2475d;
}
.piece.brown,
.cell.brown {
    background-color: #7d3e3e;
}

.gameOverOverlay {
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: grid;
    place-items: center;
    font-size: 2rem;
    color: white;
    text-align: center;
}
